<app-header></app-header>
<!-- <div class="bg-white py-3 px-5">
    <div class="text-gray-600 flex pr-1 whitespace-nowrap text-lg items-center">
        <span class="pr-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" data-slot="icon" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
            </svg>

        </span>
        Buyers Self Registration
    </div>
</div> -->
<div class="sm:px-4 md:px-36 mt-xl-2">
    <div class="flex justify-between items-center">
        <div class="sm: text-xl md:text-4xl font-bold text-sky-900 notranslate">Crop Stock Dynamics</div>
        <div class="w-28">
            <img src="assets/copra_logo.png" class="">
        </div>
    </div>
    <div class="py-3 md:grid md:grid-cols-3 bg-gray-50 rounded-lg px-2">
        <div class="border border-sky-900">
            <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'individual-reqs' | translate }}</div>
            <div class="px-2 py-4">
                {{ 'individual-reqs-info' | translate }}
                <ul class="p-4">
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'nida_id' | translate }}</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'tin_number'|translate }}</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'lga_license'|translate }} ({{ 'if-multiple'|translate }})</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'business_license'|translate }}</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'tax_clearance'|translate }}</li>
                </ul>
            </div>
        </div>
        <div class="border border-sky-900">
            <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'company-reqs'|translate }}</div>
            <div class="px-2 py-4">
                {{ 'company-reqs-info'|translate }}
                <ul class="p-4">
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'cert_of_reg'|translate }}</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'tin_number'|translate }}</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'business_license'|translate }}</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'tax_clearance'|translate }}</li>
                    <li class="items-center flex"><span class="pr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </span>{{ 'bank_info'|translate }}</li>
                </ul>

            </div>
        </div>
        <div class="border border-sky-900">
            <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'reg-title'|translate }}</div>
            <div class="px-3 py-4">
                <form [formGroup]="addForm" (ngSubmit)="onSubmit()" class="">
                    <div class="row px-2">
                        <div class="col-md-12 px-1"><mat-form-field class="w-100" appearance="outline"> <mat-label>{{ 'fullname'|translate }}</mat-label>
                                <input matInput placeholder="Enter Name" formControlName="full_name" required>
                                <mat-error *ngIf="addForm.get('full_name').invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 px-1"><mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{ 'phone'|translate }}</mat-label>
                                <input matInput type="tel" placeholder="255734250512" formControlName="phone" required>
                                <mat-error *ngIf="addForm.get('phone').invalid">{{getPhoneErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-md-12 px-1"><mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{ 'email'|translate }}</mat-label>
                                <input matInput placeholder="Email" formControlName="email" required>
                                <mat-error *ngIf="addForm.get('email').invalid">{{getEmailErrorMessage()}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-12 column">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'category'|translate }}</mat-label>
                            <mat-select placeholder="Select Category" formControlName="category" required>
                                <mat-option value="Buyer">Buyer</mat-option>
                                <mat-option value="Seller">Seller</mat-option>
                                <!-- <mat-option value="Broker">Broker</mat-option> -->
                                <mat-option value="Transporter">Transporter</mat-option>
                                <!-- <mat-option value="Warehouse Owner">Warehouse Owner</mat-option> -->
                                <!-- <mat-option value="Warehouse Operator">Warehouse Operator</mat-option> -->
                                <!-- <mat-option value="Market Manager">Market Manager</mat-option> -->
                                <!-- <mat-option value="Checkpoint Manager">Checkpoint Manager</mat-option> -->
                                <mat-option value="Processor">Processor</mat-option>
                                <mat-option value="Exporter">Exporter</mat-option>
                                <mat-option value="Importer">Importer</mat-option>
                                <mat-option value="Produce Cleaning Centre">Produce Cleaning Centre</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('category').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 column hidden">
                        <mat-form-field class="w-100" appearance="fill"><mat-label>Date (13/1/2001)</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"> <mat-icon
                                    matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="addForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="">
                        <button type="submit" class="  block w-full" mat-button><i class="fa fa-spinner fa-spin"
                                *ngIf="submitting"></i>{{ 'submit'|translate }}</button>
                    </div>
                    <div class="py-2">
                        <a href="/login"
                            class="text-center px-3 py-2 border rounded-lg block w-full bg-red-500 text-white hover:bg-red-600 hover:no-underline">{{ 'cancel'|translate }}</a>

                    </div>

                </form>
            </div>
        </div>
    </div>
</div>