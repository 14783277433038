<div class="bg-gray-100">

    <div class="md:container mx-auto my-5 sm:p-0 md:p-5">
        <div class="md:flex no-wrap md:-mx-2 ">
            <!-- Left Side -->
            <div class="w-full md:w-3/12 md:mx-2">
                <!-- Profile Card -->
                <div class="bg-white p-3 border-t-4 border-green-400">
                    <div class="image overflow-hidden">
                        <img class="h-auto w-full mx-auto"
                            src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                            alt="">
                    </div>
                    <div class="flex justify-center">
                        <img [src]="(attachmentUrl + profile.image)" *ngIf="profile.image"
                            class="rounded-full px-2 py-2 ring-8 ring-gray-50 object-top w-24 h-24">

                        <img src="assets/tz_logo.png" *ngIf="!profile.image"
                        class="rounded-full px-2 py-2 ring-8 ring-gray-50 object-top w-24 h-24">
                    </div>
                    <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{{ profile.full_name }}</h1>
                    <h3 class="text-gray-600 font-lg text-semibold leading-6">{{ 'category'|translate }}: {{
                        profile?.trader?.trader_category }}</h3>

                    <ul
                        class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                        <li class="flex items-center py-3">
                            <span>{{ 'status'|translate }}</span>
                            <span class="ml-auto">
                                <span *ngIf="profile.approval_status === 'approved'"
                                    class="bg-green-700 text-white py-1 px-2 rounded text-sm">{{ profile.approval_status
                                    }}</span>
                                <span *ngIf="profile.approval_status !== 'approved'"
                                    class="bg-amber-700 text-white py-1 px-2 rounded text-sm">{{ profile.approval_status
                                    }}</span>
                            </span>
                        </li>
                        <li class="flex items-center py-3">
                            <span>Created At</span>
                            <span class="ml-auto">{{ profile.created_at.split('T')[0] }}</span>
                        </li>
                    </ul>
                </div>
                <!-- End of profile card -->
                <div class="my-4"></div>
            </div>
            <!-- Right Side -->
            <div class="w-full md:w-9/12 md:mx-2 min-h-64">
                <!-- Profile tab -->
                <!-- About Section -->
                <div class="bg-white p-3 shadow-sm rounded-sm">
                    <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                        <span clas="text-green-500">
                            <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </span>
                        <span class="tracking-wide">About</span>
                    </div>
                    <div class="text-gray-700">
                        <div
                            class="grid md:grid-cols-3 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF] [&>*:nth-child(even)]:dark:bg-gray-600">
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'fullname'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile.full_name }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">Username</div>
                                <div class="px-2 py-2 col-span-2">{{ profile.username }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">Contact No.</div>
                                <div class="px-2 py-2 col-span-2">{{ profile.phone }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'address'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile.trader?.address }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'email'|translate }}</div>
                                <div class="px-2 py- col-span-2">
                                    <a class="text-blue-800 text-wrap" href="mailto:{{ profile.email }}">{{ profile.email }}</a>
                                </div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold"> {{ 'type'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile?.trader?.type }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="py-2 bg-gray-100"></div>
                    <div class="text-gray-700" *ngIf="showAll">
                        <div
                            class="grid md:grid-cols-3 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF] [&>*:nth-child(even)]:dark:bg-gray-600">
                            <div class="grid grid-cols-3" *ngIf="profile?.trader?.type === 'international'">
                                <div class="px-2 py-2 font-semibold">{{ 'country'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile?.trader_country }}</div>
                            </div>
                            <div class="grid grid-cols-4 col-span-2" *ngIf="profile?.trader?.category === 'individual'">
                                <div class="px-2 py-2 font-semibold">NIDA</div>
                                <div class="px-2 py-2 col-span-3">{{ profile?.trader?.nida }}</div>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-3 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF] [&>*:nth-child(even)]:dark:bg-gray-600"
                            *ngIf="profile?.trader?.type !== 'international'">
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'region'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile?.region.name }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'district'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile?.district.name }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'ward'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile?.ward.name }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'village_street'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile?.village.name }}</div>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-3 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF] [&>*:nth-child(even)]:dark:bg-gray-600"
                            *ngIf="profile?.trader?.category !== 'individual'">
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'business_name'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile.trader?.company_name }}</div>
                            </div>
                            <div class="grid grid-cols-3">
                                <div class="px-2 py-2 font-semibold">{{ 'tin_number'|translate }}</div>
                                <div class="px-2 py-2 col-span-2">{{ profile.trader?.tin_number }}</div>
                            </div>
                        </div>
                    </div>

                    <button (click)="showAllInfo()"
                        class="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">Show
                        {{ showAll ? 'Less' : 'All' }} Information</button>


                </div>
                <!-- End of about section -->

                <div class="my-4"></div>

                <!-- Attachments -->
                <div class="bg-white p-3 shadow-sm rounded-sm">

                    <div class="grid grid-cols-3">
                        <div>
                            <div class="flex  col-span-2items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                <span clas="text-green-500">
                                    <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                    </svg>
                                </span>
                                <span class="tracking-wide">{{ 'attachments'|translate }}</span>
                            </div>
                            <ul class="list-inside grid grid-cols-1 md:grid-cols-2 gap-2">
                                <li *ngIf="profile.trader?.business_license">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.business_license }}" target="_blank" rel="noopener noreferrer">{{ 'business_license'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">business_license</div>
                                </li>
                                <li *ngIf="profile.trader?.tax_clearance">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.tax_clearance }}" target="_blank" rel="noopener noreferrer">{{ 'tax_clearance'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">tax_clearance</div>
                                </li>
                                <li *ngIf="profile.trader?.tin_certificate">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.tin_certificate }}" target="_blank" rel="noopener noreferrer">{{ 'tin_certificate'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">tin_certificate</div>
                                </li>
                                <li *ngIf="profile.trader?.certificate_of_inc">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.certificate_of_inc }}" target="_blank" rel="noopener noreferrer">{{ 'cert_of_inc'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">certificate_of_inc</div>
                                </li>
                                <li *ngIf="profile.trader?.memorandum">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.memorandum }}" target="_blank" rel="noopener noreferrer">{{ 'memorandum'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">memorandum</div>
                                </li>
                                <li *ngIf="profile.trader?.article_of_association">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.article_of_association }}" target="_blank" rel="noopener noreferrer">{{ 'article_of_association'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">article_of_association</div>
                                </li>
                                <li *ngIf="profile.trader?.lga_permit">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.lga_permit }}" target="_blank" rel="noopener noreferrer">{{ 'lga_permit'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">lga_permit</div>
                                </li>
                                <li *ngIf="profile.trader?.vat_certificate">
                                    <div class="text-teal-600"><a href="{{ attachmentUrl }}{{ profile.trader?.vat_certificate }}" target="_blank" rel="noopener noreferrer">{{ 'vat_certificate'|translate }}</a></div>
                                    <div class="text-gray-500 text-xs">vat_certificate</div>
                                </li>
                            </ul>
                        </div>
                        <div>
                    </div>
                    <!-- End of Attachments -->
                </div>
                <!-- End of profile tab -->
            </div>
        </div>
    </div>
</div>