<div class="px-20 relative border-4 border-black">
    <div class="absolute right-1/4 top-20" *ngIf="isLoading">
        <img src="assets/loader.gif" alt="">
    </div>

    <div class="absolute top-2 right-2">
        <div (click)="downloadAsPDF()"
            class="text-blue-500 bg-gray-200 hover:bg-gray-100 px-2.5 py-1.5 rounded-md text-lg cursor-pointer"><i
                class="fa fa-print"></i></div>
    </div>
    <div class="print-content relative" id="permit">
        <div class="flex justify-between py-3">
            <img src="assets/tz_logo.png" class="object-cover">
            <div class="">
                <div class="flex justify-center py-1 font-bold">JAMHURI YA MUUNGANO WA TANZANIA</div>
                <div class="flex justify-center py-1 font-bold">WIZARA YA KILIMO</div>
                <div class="flex justify-center py-1 font-bold">MAMLAKA YA UDHIBITI WA</div>
                <div class="flex justify-center py-1 font-bold">NAFAKA NA MAZAO MCHANGANYIKO (COPRA)</div>
            </div>
            <div class="h-24">
                <img src="assets/copra_logo.png" class="w-full h-full object-cover">

            </div>
        </div>
        <div class="mt-10">
            <div class="flex justify-center pt-1 font-black text-xl">CHETI CHA USAJILI</div>
            <div class="flex justify-center pt-1 font-black text-xl">{{ person }} WA NAFAKA NA MAZAO</div>
            <div class="flex justify-center pb-1 font-black text-xl">MCHANGANYIKO</div>
            <!-- <div class="flex justify-center py-1">(IMETOLEWA NA KIFUNGU CHA 4(2) (F) CHA SHERIA NA. 19 YA 2009)</div> -->
            <div class="flex justify-end mt-5 mb-2">
                <div class="flex space-x-1 pr-2">
                    <div class="uppercase font-bold">USAJILI NAMBA:</div>
                    <div class="">{{ application?.application_number }}</div>
                </div>
            </div>
            <div class="mt-10">
                <div class="">
                    Ninathibitisha kuwa <span class="pl-1 pr-1 notranslate font-bold">{{ application?.trader.company_name }}</span> imesajiliwa chini ya kifungu cha 4(2) (f) kupitia sheria ya Nafaka na Mazao Mchanganyiko na. 19 ya 2009 kushiriki katika {{ action }} na biashara ya mazao yafuatayo:  <span class="font-bold  mr-1">{{ application?.crops_list }} </span>katika wilaya
                    zifuatazo: <span class="font-bold ">{{ application?.councils_list }}</span>.
                </div>
            </div>
            <div class="flex space-x-1 mt-3">
                <div class="">Tarehe ya usajili:</div>
                <div class="">{{ application?.reg_date | date }}</div>
            </div>
            <!-- <div class="flex space-x-1">
                <div class="">Imetolewa Tarehe:</div>
                <div class="">{{ application?.operation_date | date }}</div>
            </div> -->
            <div class="mt-2">
                Cheti hiki ni halali kuanzia {{ application?.operation_date | date }} hadi {{ application?.end_date | date }}
            </div>
            <div class="my-4">
                Imesaini kwa niaba ya Mamlaka.
            </div>
            <div class="mt-0">
                <img src="assets/sign.png" alt="">
                <div class="uppercase font-black">MKURUGENZI MKUU</div>
                
            </div>

            <div class="flex justify-center">
                <qrcode [qrdata]="code" [width]="156" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>

    </div>
</div>