import { AddTraderComponent } from './../add-trader/add-trader.component';
import { DeleteDataComponent } from './../../delete-data/delete-data.component';
import { ManagementService } from './../../management.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SendSmsComponent } from '../send-sms/send-sms.component';

@Component({
  selector: 'app-traders',
  templateUrl: './traders.component.html',
  styleUrls: ['./traders.component.css']
})
export class TradersComponent implements OnInit, AfterViewInit {

  isLoading = true;
  traders;
  category = 'Buyer';
  categoryName;
  imageUrl = environment.imageUrl;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    public active: ActivatedRoute,
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService
  ) { 

    // active.url.subscribe(
    //   data => {
    //     // console.log(data[2].path)
    //     this.category = data[2].path;
    //     this.categoryName = this.category
    //     if(this.category == 'all') {
    //       this.category = null;
    //       this.categoryName = 'trader'
    //     }
    //   }
    // );
  }

  ngOnInit(): void {
    this.categoryName = this.category;
    // this.getTraderData();
  }

  ngAfterViewInit() {
    this.getTraderData();
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
      buttons: [
          'copy', 'csv', 'excel', 'print'
      ]
    };
  }


  getTraderData() {
    this.manServ.getCategoryTraders(this.category).subscribe(
      data => {
        this.traders = data;
        this.isLoading = false;

        this.rerender();
      }
    );
  }
  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  onAddTrader(value) {
    const dialogRef = this.dialog.open(AddTraderComponent, {
      width: '700px',
      height: '690px',
      data: {category: this.categoryName, action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTraderData();
    });
  }

  editTrader(Id, regionId, districtId, wardId, value) {
    const dialogRef = this.dialog.open(AddTraderComponent, {
      width: '700px',
      height: '690px',
      data: {id: Id, category: this.categoryName, action: 'edit', region: regionId, district: districtId, ward: wardId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getTraderData();
      // this.edit.next();
    });
  }


  deleteTrader(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'trader' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTraderData();
      // this.edit.next();
    });
  }

  onSendMessage() {
    const dialogRef = this.dialog.open(SendSmsComponent, {
      width: '500px',
      // height: '400px',
      data: {category: this.categoryName }
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getTraderData();
    });
  }

  filterCategory() {
    // console.log(this.category);
    this.categoryName = this.category;
    this.isLoading = true;
    this.getTraderData();
  }
}
