import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from 'src/app/modules/management/management.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-buyer-register',
  templateUrl: './buyer-register.component.html',
  styleUrls: ['./buyer-register.component.css'],
})
export class BuyerRegisterComponent implements OnInit {
  regions;
  districts;
  wards;
  villages;
  crops;
  edit;
  countries;
  isRequired = false;
  isDomestic = false;
  isIndividual = true;
  tinmask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  nidamask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
  ];
  editCrops = [];
  selectedImage;
  selectedBusinessLicense;
  selectedCertificateOfInc;
  selectedTINCertificate;
  selectedTaxClearance;
  imageSrc: string;

  submitting = false;

  // categories = [
  //   'Buyer',
  //   'Seller',
  //   'Broker',
  //   'Transporter',
  //   'Warehouse Owner',
  //   'Warehouse Operator',
  //   'Market Manager',
  //   'Checkpoint Manager',
  //   'Processor',
  //   'Exporter',
  //   'Importer',
  // ];

  addForm = this.fb.group({
    full_name: ['', Validators.required],
    phone: ['', Validators.pattern(/^(255)[0-9]{9}$/)],
    category: ['', Validators.required],
    email: ['', [Validators.pattern(/.+@.+\..+/), Validators.required]],
    address: [''],
    company_name: [''],
    tin_number: [''],
    date: ['', Validators.required],
    type: [''],
    // crops : ['', Validators.required],
    village_id: [''],
    ward_id: [''],
    district_id: [''],
    region_id: [''],
    country_id: [''],
    business_license: [''],
    certificate_of_inc: [''],
    nida: [''],
    tin_certificate: [''],
    tax_clearance: [''],
    image: [''],
  });

  editForm = this.fb.group({
    full_name: ['', Validators.required],
    phone: ['', Validators.pattern(/^(255)[0-9]{9}$/)],
    category: ['', Validators.required],
    email: ['', [Validators.pattern(/.+@.+\..+/), Validators.required]],
    address: [''],
    company_name: [''],
    tin_number: [''],
    date: ['', Validators.required],
    // crops : ['', Validators.required],
    village_id: [''],
    ward_id: [''],
    district_id: [''],
    region_id: [''],
    country_id: [''],
    image: FormControl[''],
  });

  constructor(
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private authService: AuthService,
    private route: Router
  ) {
    this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    if (this.authService.getId()) {
      this.route.navigate(['/']);
    }

    this.getCountries();
    this.getAllRegions();
    this.getCrops();
    this.addForm.get('date').setValue(new Date());
  }

  changeValidation(countryId) {
    // if (countryId === 223) {
    //   this.isRequired = true;
    //   this.isDomestic = true;
    //   this.addForm.get('region_id').setValidators(Validators.required);
    //   this.addForm.get('district_id').setValidators(Validators.required);
    //   // this.addForm.get('ward_id').setValidators(Validators.required);
    //   // this.addForm.get('village_id').setValidators(Validators.required);
    // }
    // else {
    //   this.isRequired = false;
    //   this.addForm.get('region_id').clearValidators();
    //   this.addForm.get('district_id').clearValidators();
    //   this.addForm.get('ward_id').clearValidators();
    //   this.addForm.get('village_id').clearValidators();
    //   this.addForm.get('region_id').setValue('');
    //   this.addForm.get('district_id').setValue('');
    //   this.addForm.get('ward_id').setValue('');
    //   this.addForm.get('village_id').setValue('');
    // }
  }

  changeTypeValidation(type) {
    if (type === 'domestic') {
      this.isDomestic = true;
      this.addForm.get('region_id').setValidators(Validators.required);
      this.addForm.get('district_id').setValidators(Validators.required);
      // this.addForm.get('ward_id').setValidators(Validators.required);
      // this.addForm.get('village_id').setValidators(Validators.required);
      this.addForm.get('country_id').clearValidators();
      this.addForm.get('country_id').setValue('223');
    } else {
      this.isDomestic = false;
      this.addForm.get('country_id').setValidators(Validators.required);

      this.addForm.get('region_id').clearValidators();
      this.addForm.get('district_id').clearValidators();
      this.addForm.get('ward_id').clearValidators();
      this.addForm.get('village_id').clearValidators();

      this.addForm.get('region_id').setValue('');
      this.addForm.get('district_id').setValue('');
      this.addForm.get('ward_id').setValue('');
      this.addForm.get('village_id').setValue('');
    }
  }

  changeCategoryValidation(type) {
    if (type === 'individual') {
      this.isIndividual = true;
      this.addForm.get('nida').setValidators(Validators.required);

      this.addForm.get('tin_number').clearValidators();
      this.addForm.get('company_name').clearValidators();
      this.addForm.get('tax_clearance').clearValidators();
      this.addForm.get('certificate_of_inc').clearValidators();
      this.addForm.get('tin_certificate').clearValidators();
      this.addForm.get('business_license').clearValidators();

      this.addForm.get('tin_number').setValue('');
      this.addForm.get('company_name').setValue('');
      this.addForm.get('tax_clearance').setValue('');
      this.addForm.get('certificate_of_inc').setValue('');
      this.addForm.get('tin_certificate').setValue('');
      this.addForm.get('business_license').setValue('');
    } else {
      this.isIndividual = false;
      this.addForm.get('tin_number').setValidators(Validators.required);
      this.addForm.get('company_name').setValidators(Validators.required);
      this.addForm.get('tax_clearance').setValidators(Validators.required);
      this.addForm.get('certificate_of_inc').setValidators(Validators.required);
      this.addForm.get('tin_certificate').setValidators(Validators.required);
      this.addForm.get('business_license').setValidators(Validators.required);

      this.addForm.get('nida').clearValidators();

      this.addForm.get('nida').setValue('');
    }
  }

  getCountries() {
    this.manServ.getCountries().subscribe((data) => {
      this.countries = data;
    });
  }
  getAllRegions() {
    this.manServ.getAllRegions().subscribe((data) => {
      this.regions = data;
    });
  }

  getDistricts(regionId) {
    this.manServ.getAllDistricts(regionId).subscribe((data) => {
      this.districts = data;
    });
  }

  getWards(districtId) {
    this.manServ.getWards(districtId).subscribe((data) => {
      this.wards = data;
    });
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe((data) => {
      this.villages = data;
    });
  }

  getCrops() {
    this.manServ.getCrops().subscribe((data) => {
      this.crops = data;
    });
  }
  getErrorMessage() {
    return 'This field is required';
  }

  getEmailErrorMessage() {
    return 'Wrong email pattern';
  }
  getPhoneErrorMessage() {
    return 'Wrong phone pattern, please check the placeholder';
  }

  onSubmit() {
    console.log(this.addForm.value);
    var formData: any = new FormData();
    formData.append('full_name', this.addForm.controls['full_name'].value);
    formData.append('address', this.addForm.controls['address'].value);
    formData.append('phone', this.addForm.controls['phone'].value);
    formData.append('tin_number', this.addForm.controls['tin_number'].value);
    formData.append('category', this.addForm.controls['category'].value);
    formData.append('email', this.addForm.controls['email'].value);
    formData.append('category', this.addForm.controls['category'].value);
    formData.append(
      'company_name',
      this.addForm.controls['company_name'].value
    );
    formData.append('region_id', this.addForm.controls['region_id'].value);
    formData.append('district_id', this.addForm.controls['district_id'].value);
    formData.append('ward_id', this.addForm.controls['ward_id'].value);
    formData.append('village_id', this.addForm.controls['village_id'].value);
    formData.append('country_id', this.addForm.controls['country_id'].value);
    formData.append('nida', this.addForm.controls['nida'].value);
    formData.append('type', this.addForm.controls['type'].value);
    formData.append('user_id', localStorage.getItem('asdsUserId'));
    formData.append('image', this.selectedImage);
    formData.append('business_license', this.selectedBusinessLicense);
    formData.append('certificate_of_inc', this.selectedCertificateOfInc);
    formData.append('tin_certificate', this.selectedTINCertificate);
    formData.append('tax_clearance', this.selectedTaxClearance);

    // this.addForm.get('category').setValue(this.data.category);
    // const formData = {
    //   ...this.addForm.value,
    //   'user_id':  localStorage.getItem('asdsUserId')
    // }
    if (this.addForm.valid) {
      console.log('valid');
      this.submitting = true;
      this.manServ.registerUser(formData).subscribe((resp: any) => {
        if (resp['resp'] == 'User exists') {
          this.snackBar.open(
            'Failed',
            'Your account exist, use your credentials to login',
            {
              duration: 5000,
            }
          );
          this.submitting = false;
        } else {
          // alert('Your username is: ' + resp['username'] + ' and password is: ' + resp['password'])
          this.snackBar.open(
            'Registered',
            'Check your email for login credentials',
            {
              duration: 50000,
            }
          );
          this.route.navigate(['/login']);
          this.submitting = false;
        }
      });
    } else {
      this.snackBar.open('Error', 'Make sure the required fields are filled', {
        duration: 10000,
      });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader.result as string;

        // this.userForm.patchValue({
        //   fileSource: reader.result,
        // });

        this.selectedImage = event.srcElement.files[0];
      };
    }
  }

  onBusinessLicenseChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedBusinessLicense = event.srcElement.files[0];
      };
    }
  }

  onTINCertificateChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedTINCertificate = event.srcElement.files[0];
      };
    }
  }

  onTaxClearanceChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedTaxClearance = event.srcElement.files[0];
      };
    }
  }

  onCertificateOfIncChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedCertificateOfInc = event.srcElement.files[0];
      };
    }
  }
}
